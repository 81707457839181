/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: false // Set to false to use static SVG
};

const greeting = {
  username: "Aditya B.",
  title: "Hi all, I'm Aditya",
  subTitle: emoji(
    "A dedicated AI CRM Specialist and Software Engineer with a perpetual passion for DevOps. Experienced in implementing AI solutions to enhance customer relationship management 🤖 and streamline deployment processes 🚀."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1TAtPyFjxA2OAACTQQ3h_VnQYZgqQdg7v/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/AdityaBhanwadiya",
  linkedin: "https://www.linkedin.com/in/aditya-bhanwadiya/",
  gmail: "bhanwadiya.a@northeastern.edu",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY TECHIE WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Design and implement end-to-end automation solutions for document processing and workflow optimization using AI and machine learning."
    ),
    emoji("⚡ Develop scalable cloud infrastructure and DevOps pipelines using AWS, Azure, Kubernetes, and Terraform."),
    emoji(
      "⚡ Integrate third-party services like Salesforce, Azure OpenAI, and AWS for seamless data workflows and enhanced efficiency."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Northeastern University",
      logo: require("./assets/images/neulogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "January 2023 - May 2025",
      desc: "Coursework:",
      descBullets: [
        "Programming Design Paradigm", "Database Management Systems", "Algorithms", "Machine Learning", "Introduction to Data Management and Processing", "Cloud Computing", "Web Development"

      ]
    },
    {
      schoolName: "Dharmsinh Desai University",
      logo: require("./assets/images/ddulogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2018 - May 2022",
      desc: "Coursework:",
      descBullets: [
        "Artificial Intelligence",
        "Distributed Computing",
        "Computer Networks",
        "E-Commerce and E-Security",
        "Operating systems",
        "Object-Oriented Programming",
        "Data and File Structures",
        "Algorithms",
        "Data Mining and Data Warehousing",
        "Database Management System",
        "Software Engineering",
        "Web Technologies (.net)",
        "Advanced Technologies (J2EE, Spring, Hadoop, Hibernate, JS Frameworks)",
        "Design Patterns",
        "System Design and Practice",
        "Computer Architecture",
        "Computer Peripherals and Interfacing",
        "Theory of Computation",
        "Microprocessors",
        "Communication Systems",
        "Digital Electronics",
        "Assembly Language Programming",
        "Compiler Design",
        "Algebra",
        "Calculus",
        "Graph Theory",
        "Probability"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "AI CRM Developer",
      company: "Beround",
      companylogo: require("./assets/images/beroundlogo.png"),
      date: "June 2024 – Present",
      descBullets: [
        "Partnered with some of the largest finance companies in North America, introducing Equipment as a Service (EaaS), with potential expansion into the Healthcare sectors.",
        "Led a team and built an Intelligent Document Processor using Python, Azure Form Recognizer, and Azure OpenAI to automate reading and validating credit applications, with workflows triggered via Azure Logic Apps and Salesforce integration.",
        "Automated data ingestion from email attachments and Azure Data Lake, leveraging APIs for data validation and error notifications through Azure Notification Hub and SendGrid.",
        "Streamlined integration with partner financial institutions for direct data flow, improving accuracy and operational efficiency.",
        "Achieved over 95% accuracy in critical field validation by using performance metrics to continuously refine OCR and validation logic through rigorous testing and comparison against manually validated inputs.",
        "Reduced processing time from 45-60 minutes to 5-7 minutes, increasing throughput by over 700%, while optimizing manpower from 10 to 3 employees, saving ~$250k annually and boosting quarterly revenue by over $500k."
    ]
    },
    {
      role: "Lead Teaching Assistant",
      company: "Northeastern University",
      companylogo: require("./assets/images/neulogo.png"),
      date: "Sept 2023 – May 2024",
      descBullets: [
        "Assisted students in mastering database design principles, ER modeling, and SQL queries for CS3200 Database Design, using MySQL and PostgreSQL.",
        "Guided students in developing scalable programming techniques, handling large datasets with Hadoop, and performing distributed computing with Spark for DS4300 Large Scale Programming.",
        "Supported graduate students in advanced database management concepts, focusing on normalization, query optimization, and transaction management for CS5200 Database Management System, leveraging Oracle and SQL Server."
      ]
      
    },
    {
      role: "Software Engineer Intern",
      company: "Accenture",
      companylogo: require("./assets/images/accenture_new.png"),
      date: "June 2022 – December 2022",
      descBullets: [
        "Led a cross-functional team of 20+ engineers from India and the US, driving projects and presentations, and demonstrating strong leadership and collaboration skills.",
        "Developed custom Apex classes, triggers, and SOQL queries to automate complex business logic, showcasing critical thinking and problem-solving abilities.",
        "Designed and implemented a ChatBot using Service Cloud for enhanced self-service options for insurance customers.",
        "Leveraged Omni Channel for skill-based routing of customer chats to appropriate agents.",
        "Utilized Salesforce's OmniStudio components to enhance business processes and user experiences through analytical work.",
        "Optimized workflows, user interfaces, and system performance, addressing diverse challenges with an adaptable mindset."
        ]
    },
    {
      role: "Software Engineer Intern",
      company: "Indian Space Research Organization",
      companylogo: require("./assets/images/isrologo.png"),
      date: "December 2021 – April 2022",
      descBullets: 
      [ "Developed a 35GB 3D dataset for predicting accumulated evapotranspiration using Google Earth Engine's GSMaP and Xarray, with advanced normalization, data augmentation, and spatial autocorrelation analysis.", "Utilized various deep learning models (ANN, RNN, LSTM, Bi-directional LSTM, CNN-LSTM), applied statistical tests (ANOVA, t-tests), and performed hyperparameter tuning to achieve 97% accuracy." ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  
  projects: [
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "AutoDocX",
      projectDesc: "Transform your credit application processing with our AI-powered Intelligent Document Processor, reducing manual work and cutting processing time from an hour to just minutes, while boosting accuracy and saving operational costs.",
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "Health Plan Recommendation System",
      projectDesc: "Used UCI ML Drug Review Dataset followed by a comprehensive approach of data preprocessing and exploration. Utilized BeautifulSoup4, WordCloud, NLTK, Joblib, and other standard Python libraries. Implemented machine learning models, including Multinomial Naive Bayes and Passive Aggressive Classifier with and without TFIDF vectorization, as well as GPT-2 to generate personalized health plans."
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "NetMeds: Your Online Pharmacist",
      projectDesc: "Designed a robust, CLI based backend system for online medicine delivery, comprising of 3 actors and adhering to 3NF. Formulated a highly scalable architecture, anchored by a complex ER Diagram involving more than 15 entities, over 20 stored procedures, numerous triggers, views, and functions to extract visual insights from a relational database"
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "snapit.ml",
      projectDesc: "Utilized the Mercari dataset from Kaggle addressing outliers and missing values. Implemented lemmatization, bag of words, and countVectorizer to optimize pricing recommendations for a diverse range of products. Utilized regression methods (Ridge, XGBoost, LightBGM) with best results from LinearSVR. Implemented Flask backend, HTML/CSS frontend, and stored the model on AWS EC2 for snapit.ml access"
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "Integrated Bike Sales and Service Management System",
      projectDesc: "Designed and implemented a streamlined database management system with MySQL for online motorcycle purchasing, resulting in a 30% reduction in processing time. Managed seamless after-purchase services to improve customer satisfaction. Formulated 50+ complex SQL Queries, Stored procedures, Joints, and Triggers to retrieve data insights from relational database. Constructed ER diagram, Schema, and 10+ Data Dictionary tables."
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "GrowMore - Application for Farmer’s betterment",
      projectDesc: "Sidelining the middlemen and their hefty commissions, connecting farmers directly to public and experts. Adhering to agile model, utilized Figma to design wireframes, backend support using Firebase, implemented using Java and Android Studio. Integrated Fast2SMS API to keep users updated for their orders. Performed Unit & Integration Testing, summarizing the project into a document for easy access, led to user satisfaction achieving a 20% increase in positive feedback."
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "Image Manipulation and Enhancement",
      projectDesc: "Implemented user-friendly text, script, and GUI-based inputs using JSwing framework and a structured Model-View-Controller (MVC) architecture resulting in a 20% increase in overall user productivity. Implemented Command Design Pattern using Interfaces, abstract classes, enums enabling to add new features using SOLID principles to ensure modular code organization, leading to a 30% reduction in code complexity and revised maintainability. Unit Testing was done via JUnit4 through test-driven development and utilizing Git for seamless version control."
    },
    {
      // image: require("./assets/images/autodocxlogo.png"),
      projectName: "bunkORNER",
      projectDesc: "Streamlining canteen operation, built a web-app using Java Servlets, all the user form Sign up, profile and history section were made using JSP interacted with MySQL through Hibernate, minimizing wait times by 50% by incorporating Fast2SMS API, improving lunch break experience for students and faculty. Revamped customer satisfaction by getting a domain from Freenom and deployed on AWS, achieving a 20% increase in positive feedback."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "All India Rank - 03",
      subtitle:
        "All India Rank (AIR) 03 in NCAT (National Creative Aptitude Test 2021) Exam Category 3 out of >5000 students.",
      image: require("./assets/images/ncatlogo.png"),
      imageAlt: "National Creative Aptitude Test",
      footerLink: [
        
      ]
    },
    {
      title: "Best Performer at Accenture",
      subtitle:
        "Recognized as one of the best performers in 2022 Q4 - specifically for being a fast learner - by the Solution Architect Sr. Manager.",
      image: require("./assets/images/accenture_new.png"),
      imageAlt: "Accenture",
      footerLink: [
      ]
    },
    {
      title: "Salesforce Certifications",
      subtitle:
        "- Salesforce Certified Administrator\n- Salesforce Certified Omnistudio Consultant\n- Salesforce Certified Omnistudio Developer",
      image: require("./assets/images/salesforce.png"),
      imageAlt: "Accenture",
      footerLink: []
    },    
    {
      title: "AWS Certified Solution Architect (SAA)*",
      subtitle:
        "Focused on the design of cost and performance optimized solutions.",
      image: require("./assets/images/aws.png"),
      imageAlt: "AWS SAA",
      footerLink: [
      ]
    },
    {
      title: "Certified Kubernetes Administrator (CKA)*",
      subtitle:
        "Focuses on the skills and knowledge required to become a fully functioning Kubernetes Administrator.",
      image: require("./assets/images/kube.png"),
      imageAlt: "CKA",
      footerLink: [
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Publications",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://doi.org//10.32628/CSEIT21761",
      title: "Layman-Friendly Regional Language Unit Conversion Compiler",
      description:
        "IJSRCSEIT- Volume 7, Issue 6, November-December 2021"
    },
    {
      url: "https://doi.org/10.22214/ijraset.2021.36904",
      title: "Design and Implementation of an Android Application for Betterment of Farmers",
      description:
        "IJRASET- Volume 9, Issue VII, July 2021"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "I enjoy talking to new people so feel free to mail or connect with me",
  number: "+1 (857) 337-4957",
  email_address: "bhanwadiya.a@northeastern.edu"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
